import { theme } from "@theme/korpay-theme";
import styled, { css, keyframes } from "styled-components";

const collapseMenu = keyframes`
  0% {
    height: 0px;
    transition: .9s;
  }
  100% {
    height: 250px;
    transition: .9s;
  }
`;

export interface MenuStyledItemsProps {
  collapse?: boolean;
  isTop?: boolean;
}


const closeCollapseMenu = keyframes`
  0% {
    height: 250px;
    transition: .9s;
  }
  100% {
    height: 0px;
    transition: .9s;
  }
`;

export const MenuContainer = styled.div<MenuStyledItemsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 14px 32px;

  ${props => (props.collapse === true) && css`
    background-color: ${theme.black00};
  `}
  
  ${props => (props.collapse === false) && css`
    background-color: #171717;
  `}

  ${props => (props.isTop === false) && css`
    background-color: #171717;
    transition: .8s;
    box-shadow: -11px 2px 16px -7px #E3E3E3;
  `}

  ${props => (props.isTop === true && props.collapse === false) && css`
    background-color: transparent;
    transition: .8s;
  `}
`;

export const MenuLogo = styled.div`
  width: 139px;
  height: 51px;
`;

export const LogoFooter = styled.a`
  width: 156.74px;
  height: 199.23px;
`;

export const MenuNavigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const MenuItems = styled.div<MenuStyledItemsProps>`
  flex-direction: column;
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
    
  ${props => (props.collapse === false) && css`
    animation: ${closeCollapseMenu} .2s ease-in forwards;
    background-color: #171717;
  `}
    
  ${props => (props.collapse === true) && css`
    animation: ${collapseMenu} .3s ease-in-out forwards;
    background-color: #171717;
    gap: 16px;
    padding-top: 52px;
  `}
`;

export const MenuItem = styled.a<{ isOpen: boolean, weight?: number, color?: string }>`
  font-weight: ${({ weight }) => weight ?? 400};
  font-size: 18px;
  text-decoration: none;
  color: ${({ color }) => color ?? ' rgba(255, 255, 255, 0.50)'};
  cursor: none;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  height: ${({ isOpen }) => isOpen ? '24px' : 0};
`;

export const MenuButton = styled.div`
  background: none;
  border: none;
`;

export const MenuToggle = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  transition: all 1s;
`;

export const Bar = styled.span<{ isOpen: boolean }>`
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 5px 0;
  transition: .9s;
  border-radius: 50px;

  &:nth-child(1) {
    transform: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'rotate(-45deg) translate(-8px, 8px)' : 'rotate(0)'};
  }

  &:nth-child(2) {
    opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? '0' : '1')};
  }

  &:nth-child(3) {
    transform: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'rotate(42deg) translate(-10px, -11px)' : 'rotate(0)'};
  }
`;