import { KorpayTypography } from "@components/korpay-typography/korpay-typography";
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper";
import { theme } from "@theme/korpay-theme";
import { useNavigate } from "react-router-dom";

export interface KorpayFooterInfoButtonProps {
  icon?: JSX.Element | any;
  redirect?: string;
}

export interface KorpayFooterInfoProps {
  title?: string;
  fontSizeTitle?: string;
  fontSizeSubTitle?: string;
  itens?: {
    text?: string;
    desc?: string;
    icon?: string;
    redirect?: string;
    buttons?: KorpayFooterInfoButtonProps[];
  }[];
}

export const KorpayFooterInfo = ({ ...etc }: KorpayFooterInfoProps) => {
  const navigate = useNavigate();

  return (
    <KorpayWrapper direction="column" gap="8px">
      <KorpayWrapper paddingTop="10px" paddingBottom="0px">
        <KorpayTypography
          weight={600}
          color={theme.white00}
          size={etc.fontSizeTitle}
        >
          {etc.title}
        </KorpayTypography>
      </KorpayWrapper>
      <>
        {etc.itens?.map((item) => {
          return (
            <>
              <KorpayTypography
                weight={400}
                color={theme.footerTitle}
                size={etc.fontSizeSubTitle}
              >
                <KorpayWrapper direction="column">
                  {item.redirect ? (
                    <span
                      onClick={() => navigate(item?.redirect as string)}
                      style={{
                        color: theme.footerTitle,
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      rel="noreferrer"
                    >
                      {item.text}
                    </span>
                  ) : (
                    <div>{item.text}</div>
                  )}
                  <div>
                    {item.desc && (
                      <KorpayTypography
                        weight={300}
                        color={theme.footerTitle}
                        size="14px"
                      >
                        {item.desc}
                      </KorpayTypography>
                    )}

                    <KorpayWrapper gap="16px">
                      {item.buttons?.map((x) => {
                        return (
                          <>
                            <a href={x.redirect}>
                              <img src={x.icon} alt="" />
                            </a>
                          </>
                        );
                      })}
                    </KorpayWrapper>
                  </div>
                </KorpayWrapper>
              </KorpayTypography>
            </>
          );
        })}
      </>
    </KorpayWrapper>
  );
};
