import { KorpayPaper } from "@components/korpay-paper/korpay-paper";
import { KorpayFooter } from "@components/korpay-footer/korpay-footer";
import { KorpayStyledButton } from "@components/korpay-button/korpay-button-styles";
import { theme } from "@theme/korpay-theme";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function PoliticasPrivacidadePage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <KorpayPaper>
      <div
        style={{
          backgroundColor: "#000",
          paddingBottom: "100vh",
          padding: "150px 150px",
        }}
      >
        <div>
          <KorpayStyledButton
            onClick={() => navigate("/")}
            width="200px"
            fontSize="22px"
            height="55px"
            radius="50px"
            fontWeight={700}
            color={theme.black00}
            border="1.849px solid #F5F5F5"
            fontColor="#fff"
          >
            Voltar a Home
          </KorpayStyledButton>
        </div>

        <h1
          style={{
            color: "#fff",
            fontSize: "45px",
            letterSpacing: "0.025em",
            marginTop: "100px",
          }}
        >
          Política de Privacidade
        </h1>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          A Lei Geral de Proteção de Dados Pessoais (LGPD) estabelece regras
          sobre como as empresas devem tratar os dados pessoais nos meios
          físicos ou digitais, de forma segura e transparente. Dessa forma,
          reforçando nosso compromisso com a proteção de seus dados pessoais,
          apresentamos como as suas informações pessoais serão coletadas,
          utilizadas e protegidas, bem como quais são os seus direitos e como
          eles poderão ser exercidos. As diretivas aqui estabelecidas aplicam-se
          aos membros dos órgãos colegiados de administração, aos ocupantes de
          cargos e funções comissionadas, aos funcionários, estagiários,
          prestadores de serviços, bem como, no que couber, a todos aqueles que
          venham a ter acesso a dados pessoais, recebidos, custodiados ou
          compartilhados pelo KORPAY S/A.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>Definições</strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          Para os fins desta Política de Privacidade, consideram-se:
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          I. dado pessoal: informação relacionada a pessoa natural identificada
          ou identificável;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          II. dado pessoal sensível: dado pessoal sobre origem racial ou étnica,
          convicção religiosa, opinião política, filiação a sindicato ou a
          organização de caráter religioso, filosófico ou político, dado
          referente à saúde ou à vida sexual, dado genético ou biométrico,
          quando vinculado a uma pessoa natural;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          III. dado anonimizado: dado relativo a titular que não possa ser
          identificado, considerando a utilização de meios técnicos razoáveis e
          disponíveis na ocasião de seu tratamento;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          IV. titular: pessoa natural a quem se referem os dados pessoais que
          são objeto de tratamento;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          V. controlador: pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          VI. operador: pessoa natural ou jurídica, de direito público ou
          privado, que realiza o tratamento de dados pessoais em nome do
          controlador;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          VII. agentes de tratamento: o controlador e o operador;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          VIII. tratamento: toda operação realizada com dados pessoais, como as
          que se referem a coleta, produção, recepção, classificação,
          utilização, acesso, reprodução, transmissão, distribuição,
          processamento, arquivamento, armazenamento, eliminação, avaliação ou
          controle da informação, modificação, comunicação, transferência,
          difusão ou extração;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          IX. consentimento: manifestação livre, informada e inequívoca pela
          qual o titular concorda com o tratamento de seus dados pessoais para
          uma finalidade determinada;
        </p>
        <p
          style={{ color: "#fff", fontSize: "20px", letterSpacing: "0.025em" }}
        >
          X. eliminação: exclusão de dado ou de conjunto de dados armazenados em
          banco de dados, independentemente do procedimento empregado;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginBottom: "25px",
          }}
        >
          XI. uso compartilhado de dados: comunicação, difusão, transferência
          internacional, interconexão de dados pessoais ou tratamento
          compartilhado de bancos de dados pessoais por órgãos e entidades
          públicos no cumprimento de suas competências legais, ou entre esses e
          entes privados, reciprocamente, com autorização específica, para uma
          ou mais modalidades de tratamento permitidas por esses entes públicos,
          ou entre entes privados.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>Princípios</strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          As atividades de tratamento de dados pessoais pelo KORPAY S/A
          observarão a boa-fé e os seguintes princípios legais:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          I. finalidade: realização do tratamento para propósitos legítimos,
          específicos, explícitos e informados ao titular, sem possibilidade de
          tratamento posterior de forma incompatível com essas finalidades;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. adequação: compatibilidade do tratamento com as finalidades
          informadas ao titular, de acordo com o contexto do tratamento;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. necessidade: limitação do tratamento ao mínimo necessário para a
          realização de suas finalidades, com abrangência dos dados pertinentes,
          proporcionais e não excessivos em relação às finalidades do tratamento
          de dados;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. livre acesso: garantia, aos titulares, de consulta facilitada e
          gratuita sobre a forma e a duração do tratamento, bem como sobre a
          integralidade de seus dados pessoais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          V. qualidade dos dados: garantia, aos titulares, de exatidão, clareza,
          relevância e atualização dos dados, de acordo com a necessidade e para
          o cumprimento da finalidade de seu tratamento;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VI. transparência: garantia, aos titulares, de informações claras,
          precisas e facilmente acessíveis sobre a realização do tratamento e os
          respectivos agentes de tratamento, observados os segredos comercial e
          industrial;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VII. segurança: utilização de medidas técnicas e administrativas aptas
          a proteger os dados pessoais de acessos não autorizados e de situações
          acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
          difusão;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VIII. prevenção: adoção de medidas para prevenir a ocorrência de danos
          em virtude do tratamento de dados pessoais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IX. não discriminação: impossibilidade de realização do tratamento
          para fins discriminatórios ilícitos ou abusivos;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginBottom: "25px",
          }}
        >
          X. responsabilização e prestação de contas: demonstração pelo KORPAY
          S/A da adoção de medidas eficazes e capazes de comprovar a observância
          e o cumprimento das normas de proteção de dados pessoais e, inclusive,
          da eficácia dessas medidas.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>
          Tratamento de dados pessoais
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O KORPAY S/A realizará o tratamento de dados pessoais com finalidades
          específicas e de acordo com as seguintes bases legais:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          I. mediante o fornecimento de consentimento pelo titular;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. para o cumprimento de obrigação legal ou regulatória;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. para o tratamento e uso compartilhado de dados necessários à
          execução de políticas públicas previstas em leis e regulamentos ou
          respaldadas em contratos, convênios ou instrumentos congêneres, nos
          termos estabelecidos pela LGPD;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. quando necessário para a execução de contrato ou de procedimentos
          preliminares relacionados a contrato do qual seja parte o titular, a
          pedido do titular dos dados;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          V. para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VI. quando necessário para atender aos interesses legítimos do KORPAY
          S/A ou de terceiros, prevalecendo direitos e liberdades fundamentais
          do titular que exijam a proteção dos dados pessoais; ou
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VII. para a proteção do crédito, inclusive quanto ao disposto na
          legislação pertinente.
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          Os dados pessoais poderão ser fornecidos pelo titular ao KORPAY S/A ou
          coletados automaticamente por meios digitais
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          Os dados pessoais também poderão ser recebidos por intermédio de
          terceiros que possuam algum relacionamento com o titular dos dados,
          bem como de bases disponibilizadas por autoridades públicas (como a
          Receita Federal, Banco Central) ou por terceiros, bureaus de crédito,
          órgãos públicos, fontes públicas como Internet, meios de comunicação,
          mídias sociais e registros públicos e de outras fontes, conforme
          permitido na legislação aplicável, ou até mesmo dados tornados
          públicos pelo titular em sites ou redes sociais, sempre com a estrita
          observância à Lei.
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O KORPAY S/A poderá utilizar os dados pessoais para:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          I. assegurar a adequada identificação, qualificação e autenticação do
          titular;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. garantir maior segurança e prevenir fraudes;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. prevenir atos relacionados à lavagem de dinheiro e outros atos
          ilícitos;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. realizar análises de risco de crédito;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          V. aperfeiçoar o atendimento e os produtos e serviços prestados;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VI. ofertar produtos e serviços adequados e relevantes aos seus
          interesses e necessidades de acordo com o seu perfil;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VII. cumprir obrigações trabalhistas;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginBottom: "25px",
          }}
        >
          VIII. outras hipóteses baseadas em finalidades legítimas, sempre com a
          estrita observância à Lei.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>
          Segurança das Informações
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          Todos os dados pessoais fornecidos pelo titular ao KORPAY S/A,
          coletados automaticamente ou recebidos de fontes externas serão
          armazenados em base de dados ou meio físico seguros, com acesso
          restrito aos profissionais autorizados e qualificados que deles
          necessitem exclusivamente para o desempenho de suas funções.
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O KORPAY S/A adotará os seguintes padrões mínimos de segurança para
          proteger os dados pessoais, além daqueles definidos na Instrução nº
          054, Segurança da Informação e Cibernética:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          I. anonimização dos dados, sempre que necessário e possível;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. permissão de acesso a bases de dados ou locais de armazenamento de
          dados pessoais apenas a pessoas previamente autorizadas, comprometidas
          a manter o sigilo absoluto de tais dados;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. aplicação de mecanismos de registro de acesso capazes de
          individualizar o responsável pelo tratamento e acesso aos dados
          pessoais em decorrência da utilização dos meios digitais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. manutenção de inventário indicando momento, duração, identidade do
          funcionário ou do responsável pelo acesso e arquivo dos dados
          pessoais, com base nos registros de conexão e de acesso aos sistemas;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginBottom: "25px",
          }}
        >
          V. em caso de transmissão de dados, esse procedimento acontecerá de
          forma segura, sem que ocorra a divulgação de dados pessoais que
          possibilitem a identificação dos seus titulares.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>
          Compartilhamento de dados
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O KORPAY S/A poderá compartilhar os dados pessoais de titulares com
          terceiros nas seguintes hipóteses:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          I. verificação de informações cadastrais do titular, bem como para
          fins de cobrança e segurança;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. quando exigido para a prática das atividades comerciais, como
          propaganda, marketing e outras atividades correlatas, imprescindível
          para a prestação do serviço e necessário para melhorar a experiência
          do titular com os produtos e serviços oferecidos; nesse caso haverá um
          acordo de confidencialidade com a empresa receptora dos dados, de modo
          a garantir o estrito sigilo das informações;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. para a proteção dos interesses do KORPAY S/A em caso de conflito,
          inclusive em demandas judiciais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. em caso de transações e alterações societárias envolvendo o KORPAY
          S/A, na hipótese em que a transferência dos dados seja necessária para
          a continuidade dos serviços;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          V. com outras instituições financeiras, inclusive quando necessário,
          para o processamento de alguma transação ou outras atividades para
          execução de contrato;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VI. com bureaus de crédito, de acordo com o disposto na legislação
          aplicável, bem como para cumprimento da legislação do cadastro
          positivo, nos casos de negativação, entre outros;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VII. para identificação, prevenção e investigação de possíveis
          infrações ou atos ilícitos (inclusive fraude, lavagem de dinheiro e
          financiamento do terrorismo);
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VIII. com órgãos reguladores, outras entidades públicas, instituições
          do sistema financeiro e terceiros, inclusive para cumprimento e
          execução de obrigações legais, regulatórias e contratuais e para a
          proteção e o exercício regular de direitos;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IX. para cumprimento de requisições, solicitações e decisões de
          autoridades judiciais, administrativas ou arbitrais.
        </p>
        <strong style={{ color: "#fff" }}>
          Direitos do titular dos dados pessoais
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O KORPAY S/A assegurará os seguintes direitos ao titular dos dados
          pessoais, a qualquer momento e mediante requisição:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          I. confirmação da existência de tratamento de seus dados pessoais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. informações sobre a finalidade específica do tratamento, a forma e
          duração do tratamento e a responsabilidade dos agentes que realizam o
          tratamento;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          III. acesso aos dados coletados pelos meios digitais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IV. correção de seus dados, caso estes estejam incompletos, inexatos
          ou desatualizados;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          V. anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade com a legislação brasileira
          aplicável;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VI. portabilidade de seus dados pessoais para terceiros, mediante
          requisição expressa feita pelo próprio titular;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VII. eliminação dos dados pessoais tratados, mediante solicitação,
          exceto nos casos em que seu armazenamento deva ser feito com o fim de
          cumprir obrigações legais;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          VIII. obtenção de informações sobre o compartilhamento de seus dados
          pessoais com entidades públicas ou privadas, bem como sua finalidade;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          IX. obtenção de informações sobre a possibilidade de não fornecer o
          seu consentimento em relação ao tratamento de seus dados pessoais, bem
          como de ser informado sobre suas consequências, em caso de negativa;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          X. revogação do consentimento dado anteriormente para o tratamento dos
          dados pessoais, se for o caso.
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          Será disponibilizado canal de atendimento para o exercício dos
          direitos pelo titular dos dados pessoais.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>
          Exclusão dos Dados Pessoais
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          Os dados pessoais do titular serão excluídos, observadas as
          legislações pertinentes, quando:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          I. a finalidade para a qual o dado foi coletado seja alcançada ou
          quando o dado deixar de ser necessário ou pertinente para o alcance
          desta finalidade;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
          }}
        >
          II. quando houver revogação do consentimento pelo titular, exceto em
          caso de armazenamento determinado por obrigação legal ou regulatória;
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginBottom: "25px",
          }}
        >
          III. mediante determinação de autoridade competente para tanto.
        </p>
        <strong style={{ color: "#fff", fontSize: "35px" }}>
          Disposições Gerais
        </strong>
        <p
          style={{
            color: "#fff",
            fontSize: "20px",
            letterSpacing: "0.025em",
            marginTop: "25px",
          }}
        >
          O uso dos serviços oferecidos pelo KORPAY S/A por meio dos seus canais
          digitais, como site e aplicativo, será regulado por termos e condições
          de uso com estrita observância desta Política de Privacidade e
          Proteção de Dados Pessoais.
        </p>
      </div>
      <KorpayFooter />
    </KorpayPaper>
  );
}
