import { Route, Routes } from "react-router-dom";
import { HomePage } from "./views/pages/screens/korpay-home-page";
import { PoliticasPrivacidadePage } from "./views/pages/screens/korpay-politicas-privacidade-page";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/politicas-privacidade"
        element={<PoliticasPrivacidadePage />}
      />
    </Routes>
  );
}
