import { Size } from "@components/korpay-breakpoint/korpay-breakpoint";
import logoCompleta from "@images/logo-completa.svg";
import { margins } from "@theme/korpay-theme";
import styled, { css } from "styled-components";

export const KorpayStyledNavbar = styled.div<{ background?: boolean, isTop?: boolean }>`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 50;
    padding-left: ${margins().navbar};
    padding-right: ${margins().navbar};
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;

    ${props => (props.isTop === false) && css`
        background-color: #000;
        transition: 2s;
        box-shadow: -11px 2px 16px -7px #171717;
    `}

    ${props => (props.isTop === true) && css`
        background-color: transparent;
        transition: 1s;

        padding-left: 48px;
        padding-right: 48px;
    `}

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        padding-left: 148px;
        padding-right: 148px;

        ${props => (props.isTop === true) && css`
            background-color: transparent;
            transition: 2s;

            padding-left: 48px;
            padding-right: 48px;
        `}
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
        padding-left: 250px;
        padding-right: 250px;

        ${props => (props.isTop === true) && css`
            background-color: transparent;
            transition: 2s;

            padding-left: 48px;
            padding-right: 48px;
        `}
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        padding-left: 480px;
        padding-right: 480px;
        
        ${props => (props.isTop === true) && css`
            background-color: transparent;
            transition: 2s;

            padding-left: 148px;
            padding-right: 148px;
        `}
    }

    @media (max-width: 960px) and (max-height: 540px) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media (max-width: 1024px) and (max-height: 640px) {
        padding-left: 46px;
        padding-right: 46px;

        ${props => (props.isTop === true) && css`
            background-color: transparent;
            transition: 2s;
        `}
    }

    @media (min-width: 2560px) and (min-height: 1080px) {
        padding-left: 480px;
        padding-right: 480px;

        ${props => (props.isTop === true) && css`
            background-color: transparent;
            transition: 2s;

            padding-left: 149px;
            padding-right: 149px;
        `}
    }
`

export const KorpayStyledNavbarLogo = styled.div<{ isTop?: boolean }>`
    width:  240px;
    height: 62px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: ${`url(${logoCompleta})`};
    transition: .8s;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        width: 200px;
        height: 40px;
    }

    @media (max-width: 960px) and (max-height: 540px) {
        width: 140px;
        height: 30px;
    }

    @media (max-width: 1024px) and (max-height: 640px) {
        width: 160px;
        height: 35px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width:  240px;
        height: 62px;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        width:  240px;
        height: 62px;
    }
`

export const KorpayStyledNavbarWrapper = styled.div<{ isTop?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 30%;

    @media (min-width: ${Size.desktopM}px) and (max-height: 768px) {
        width: 35%;
    }

    @media (max-width: 960px) and (max-height: 540px) {
        width: 53%;
    }

    @media (min-width: 1024px) and (min-height: 640px) {
        width: 43%;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1040px) {
        width: 35%;
    }

    @media (min-width: ${Size.desktopM}px) and (min-height: 1938px) {
        width: 35%;
    }
`