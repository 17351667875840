import { KorpayStyledHorizontalDivider } from "@components/korpay-divider/korpay-horizontal-divider-styles"
import { KorpaySection } from "@components/korpay-section/korpay-section"
import { KorpayWrapper } from "@components/korpay-wrapper/korpay-wrapper"
import { theme } from "@theme/korpay-theme"
import { KorpayFooterInfo } from "../korpay-footer-parts/korpay-footer-info"
import { KorpayFooterQuemSomos } from "../korpay-footer-parts/korpay-footer-quem-somos"

//importação das imagens
import instagram from '@images/korpay-instagram.svg'
import linkedin from '@images/korpay-linkedin.svg'
import logo from '@images/logo-footer-korpay.svg'
import wpp from '@images/wpp.svg'

export const KorpayFooterMobile = () => {
    return (
        <KorpaySection views={1.4} color={theme.black00}>
            <KorpayWrapper width="100%" paddingTop="40px" paddingBottom="40px" direction="column" justify="space-between">
                <KorpayStyledHorizontalDivider size="1px" />

                <KorpayFooterInfo fontSizeTitle="18px"
                    fontSizeSubTitle="16px" title="Korpay" itens={KorpayBankFooterInfoDetails} />

                <KorpayFooterInfo title="Segurança e Privacidade"
                    fontSizeTitle="18px" fontSizeSubTitle="16px" itens={SegurancaEPrivacidadeFooterInfoDetails} />

                <KorpayFooterInfo title="Nosso contato"
                    fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoContatoFooterInfoDetails} />

                <KorpayFooterInfo title="Central de Relacionamento:"
                    fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoCentralFooterInfoDetails} />

                <KorpayFooterInfo title="SAC"
                    fontSizeTitle="18px" fontSizeSubTitle="16px" itens={NossoSacFooterInfoDetails} />

            </KorpayWrapper>

            <KorpayStyledHorizontalDivider size="2px" background="#303030" /> 

            <KorpayFooterQuemSomos fontSizeTitle="16px" itens={QuemSomosFooterInfoDetails} />

        </KorpaySection>
    )
}

// --== Links e conteúdos da aba "Korpay"
export const KorpayBankFooterInfoDetails = [
    {
        text: "Sobre nós"
    },
    {
        text: "Trabalhe Conosco: Seja um Kor!"
    },
    {
        text: "Perguntas Frequentes"
    }
]

// --== Links e conteúdos da aba segurança e privacidade
export const SegurancaEPrivacidadeFooterInfoDetails = [
    {
        text: "Termos de Uso e Política de Privacidade"
    },
    {
        text: "Portal da Privacidade"
    },
    {
        text: "Segurança contra fraude"
    },
    {
        text: "Política de Segurança Cibernética"
    },
    {
        text: "Segurança da Informação"
    },
]


// --== Links e conteúdos da aba segurança e privacidade
export const NossoContatoFooterInfoDetails = [
    {
        buttons: [
            {
                icon: instagram,
                redirect: ''
            },
            {
                icon: linkedin,
                redirect: ''
            },
            {
                icon: wpp,
                redirect: ''
            }
        ]
    },
]

export const NossoCentralFooterInfoDetails = [
    {
        desc: "(11) 92055-0999 (9h às 17h de segunda a sexta, exceto fins de semana e feriados)"
    },
]

export const NossoSacFooterInfoDetails = [
    {
        desc: "0800-000-1906 (9h às 17h de segunda a sexta, exceto fins de semana e feriados)"
    },
]

export const QuemSomosFooterInfoDetails = [
    {
        buttons: [
            {
                icon: logo,
                redirect: '#inicio'
            }
        ]
    },
    {
        desc: ""
    },
    {
        desc: ""
    },
]