import React from "react";
import ReactDOM from "react-dom/client";
import { HomePage } from "./views/pages/screens/korpay-home-page";
import "./assets/styles/bundle";
import { App } from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
