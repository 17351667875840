import { useKorpayScroll } from '@hooks/use-korpay-scroll';
import logo from '@images/logo-large-white.svg';
import React, { useState } from 'react';
import { Bar, MenuContainer, MenuItem, MenuItems, MenuLogo, MenuNavigation, MenuToggle } from './korpay-navbar-mobile-styles';

export const KorpayNavbarMobile: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
    const [top, setTop] = useState(true)

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuItemClick = () => {
        setIsOpen(false);
    };

    // --== Alterando estilo caso o usuário role a página
    useKorpayScroll({
        onNotTop: () => setTop(false),
        onTop: () => setTop(true)
    });

    return (
        <MenuContainer collapse={isOpen} isTop={top}>

            <MenuNavigation>
                <MenuLogo>
                    <img src={logo} alt="Logo Korpay" />
                </MenuLogo>
                <MenuToggle onClick={toggleMenu}>
                    <Bar isOpen={isOpen!} />
                    <Bar isOpen={isOpen!} />
                    <Bar isOpen={isOpen!} />
                </MenuToggle>
            </MenuNavigation>

            <MenuItems collapse={isOpen}>
                <MenuItem isOpen={isOpen!} weight={800} color='#fff'
                    href='home' onClick={handleMenuItemClick}>Início</MenuItem>
                <MenuItem isOpen={isOpen!}
                    href='sobre-nos' onClick={handleMenuItemClick}>Sobre nós</MenuItem>
                <MenuItem isOpen={isOpen!}
                    href="investimento" onClick={handleMenuItemClick}>Investimento</MenuItem>
                <MenuItem isOpen={isOpen!}
                    href="#saber-mais" onClick={handleMenuItemClick}>Quer saber mais?</MenuItem>
                <MenuItem isOpen={isOpen!}
                    href="abrir-conta" onClick={handleMenuItemClick}>Conta</MenuItem>
            </MenuItems>

        </MenuContainer>
    );
};